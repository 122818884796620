<template>
  <div class="">
    <div class="row justify-content-end align-items-end">

      <div class="col-xl-3 my-3">
        <label for="">
          Du : 
        </label>
        <input type="date" v-model="startDate" class="form-control">
      </div>

      <div class="col-xl-3 my-3">
        <label for="">
          Du : 
        </label>
        <input type="date" v-model="endDate" class="form-control">
      </div>

      <div class="col"></div>

      <div class="col-auto my-3">
        <button @click="generateAvailableDates(startDate, endDate)" class="btn btn-primary">
          <i class="fa fa-plus"></i> Recherche
        </button>
      </div>

      <div class="col-auto my-3">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Actualiser
        </button>
      </div>

    </div>
    <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            <th v-for="date in availableDates" :key="date.value" class="p-3">
              {{ date.label }} ({{ date.value }})
            </th>
          </tr>
      </thead>
     <tbody>
      <tr v-for="hour in timeSlots" :key="hour">
        <td v-for="date in availableDates" :key="date.value" class="align-top">
          <div>
            {{ hour }}
          </div>
            
          <div v-if="showReservationDetails(date.value, hour)" class=" w-100 p-0">
            <div v-for="reservation in showReservationDetails(date.value, hour)"
             :key="reservation.id" class="w-100  p-0">
              <div 
                :style="{
                  background: getColorForReference(reservation.reference),
                }"
                class="d-flex justify-content-between p-2 rounded border  ">

                <span class="fw-bold text-primary w-75">
                  {{ reservation.fullName }}
                  <br>
                  <span v-if="reservation.terrainFullName" class="bg-primary text-white rounded  px-2">
                    {{ reservation.terrainFullName }} 
                  </span>
                </span> 
                
              </div>
            </div>
          </div>

        </td>
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      availableDates: [],
      timeSlots: [],
      referenceColors: {},
      startDate:  moment().format('YYYY-MM-DD'),
      endDate: moment().add(5, 'days').format('YYYY-MM-DD'),
    };
  },
  async mounted() {
    await this.$store.dispatch("reservation/getAll");
    await this.generateAvailableDates(this.startDate, this.endDate);
    await this.generateTimeSlots();
  },
  computed: {
    ...mapGetters('reservation', {
      reservations: 'getReservations',
    }),
  },
  methods: {

    generateRandomColor() {
      const letters = '89ABCDEF'; // Use only lighter part of the color spectrum
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    },

    getColorForReference(reference) {
      // Vérifie si une `reference` a déjà une couleur attribuée
      if (!this.referenceColors[reference]) {
        // Génère et stocke une nouvelle couleur pour cette `reference`
        this.referenceColors[reference] = this.generateRandomColor();
      }
      // Retourne la couleur associée à cette `reference`
      return this.referenceColors[reference];
    },

    generateAvailableDates(startDate, endDate) {
      const dates = [];
      const locale = 'fr-FR';
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      if (isMobile) { endDate = startDate } // Show 2 days on mobile devices, 5 days on desktop devices

      const currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        // Get day name and capitalize the first letter
        let dayName = currentDate.toLocaleDateString(locale, { weekday: 'short' }).replace('.', '');
        dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

        const day = currentDate.toLocaleDateString(locale, { day: 'numeric' });

        // Get month name, shorten it to the first 4 letters, and capitalize the first letter
        let month = currentDate.toLocaleDateString(locale, { month: 'long' });
        month = month.substring(0, 4).charAt(0).toUpperCase() + month.substring(1, 4);

        dates.push({
          value: currentDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
          label: `${dayName} ${day} ${month}`,
        });

        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.availableDates = dates;
    },

    generateTimeSlots() {
      const slots = [
        '08:00', '09:10', '10:20', '11:30', '12:40',
        '13:50', '15:00', '16:10', '17:20', '18:30',
        '19:40', '20:50', '22:00', '23:10', '00:10'
      ];

      this.timeSlots = slots;
    },

    isReserved(date, hour) {
      if (!this.reservations || !Array.isArray(this.reservations)) {
        return null; // return null if this.reservations is null or not an array
      }

      const hourInt = parseInt(hour.split(':')[0], 10);
      return this.reservations.some(reservation => {
        const reservationDate = reservation.date;
        const reservationStartHour = parseInt(reservation.startTime.split(':')[0], 10);
        const reservationEndHour = parseInt(reservation.endTime.split(':')[0], 10);
        return reservationDate === date && hourInt >= reservationStartHour && hourInt < reservationEndHour;
      });
    },

   

    showReservationDetails(date, hour) {
      if (this.isReserved(date, hour)) {
        const reservations = this.reservations.filter(reservation => {
          const reservationStartHour = parseInt(reservation.startTime.split(':')[0], 10);
          const reservationEndHour = parseInt(reservation.endTime.split(':')[0], 10);
          const hourInt = parseInt(hour.split(':')[0], 10);
          return reservation.date === date && hourInt >= reservationStartHour && hourInt < reservationEndHour;
        });

        if (reservations.length > 0) {
          return reservations.map(reservation => {
            
            return {
              reference: reservation.reference,
              hour: hour,
              fullName: reservation.fullName,
              terrainFullName: reservation.terrainFullName,
            };
          });
        }
      }
      return null;
    },

    async refresh() {
      await this.$store.dispatch("reservation/getAll");
      await this.generateAvailableDates(this.startDate, this.endDate);
      await this.generateTimeSlots();
    }
  
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
